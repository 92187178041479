import Vue from 'vue'
import App from './App.vue'
import VueAnalytics from 'vue-analytics'
import router from './router'



Vue.use(VueAnalytics, {
    id: 'UA-9245898-1',
    router,
    autoTracking: {
        pageviewTemplate(route) {
            return {
                page: route.path,
                title: document.title,
                location: window.location.href,
                dimension6: "Edici&oacute;n Impresa Digital"
            }
        }
    }
})
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')