import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "ambito-impresa" */ "../views/Home.vue")
    },

    {
        path: '/:fecha/',
        name: 'Ediciones anteriores',
        component: () =>
            import ( /* webpackChunkName: "ambito-impresa" */ '../views/Home.vue')
    },
    {
        path: '/byday/:fecha/',
        name: 'Edicion por dia',
        component: () =>
            import ( /* webpackChunkName: "ambito-impresa" */ '../views/Home.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router